/*--------------------------------------------------------------
8. Header
----------------------------------------------------------------*/

.ak-site_header {
    position: relative;
    z-index: 100001;
  }
  
  .ak-site-branding {
    display: inline-block;
    max-width: 180px;
  }
  
  .ak-site_header {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0px;
    transition: all 0.4s ease;
  }
  
  .ak-site_header.ak-style1 {
    .ak-main_header_in,
    .ak-top_header_in {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      margin: 0px;
    }
  
    .ak-main-header-center {
      display: flex;
      align-items: center;
      height: 100%;
    }
  
    .header-top {
      position: relative;
      padding: 0px 10px 0px 28px;
      height: 70px;
      .wrapper {
        height: 100%;
        width: 100%;
        justify-content: space-between;
        display: flex;
        align-items: center;
        .header-logo {
          border-right: 0.3px solid #635542;
          display: flex;
          height: 0%;
          align-items: center;
          /* .logo {
            
          } */
        }
        .center-log {
          margin-right: 88px;
          @media screen and (max-width: 768px) {
            margin-right: 0px;
          }
        }
      }
      .top-main-menu {
        position: absolute;
        width: 100%;
        top: 0%;
        left: 0;
        z-index: -10;
        transition: all 0.5s ease-in-out;
        background-color: #000000e7;
        display: flex;
        flex-direction: column;
        font-style: italic;
        list-style: none;
        justify-content: center;
        opacity: 1;
        height: 0;
        .top-main-menu-li {
          height: 70px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-transform: uppercase;
          font-style: italic;
          color: #fff;
          font-family: Baskervville;
          padding-left: 15%;
          padding-right: 5%;
          transition: all 0.5s ease-in-out;
          .top-main-menu-img {
            position: relative;
            opacity: 0;
            display: none;
            object-fit: cover;
            width: 0%;
            height: 400px;
          }
        }
        li > a {
          display: none;
          font-size: 60px;
          line-height: 120%;
          display: inline-block;
          &:hover {
            transform: translateX(50px);
            color: #fff;
          }
        }
      }
    }
  }
  
  .ak-nav + .ak-toolbox {
    margin-left: 55px;
  }
  
  .ak-site_header.ak-style1 {
    &.ak-sticky-active {
      background-color: #000000da;
      box-shadow: 0 10px 10px -10px rgba(33, 43, 53, 0.1);
    }
    .ak-action_box {
      display: flex;
      align-items: center;
      .ak-action_value {
        margin-left: 15px;
      }
      > * {
        &:not(:last-child) {
          margin-right: 35px;
        }
      }
    }
    .ak-btn {
      padding: 13px 15px;
    }
  }
  
  .ak-site_header_full_width {
    .container {
      max-width: 100%;
      padding: 0 100px;
    }
  }
  
  .ak-site_header_style1 {
    border-bottom: 1px solid #1a188d;
    .ak-main-header-center {
      display: flex;
      align-items: center;
    }
    .ak-nav + .ak-header_toolbox.ak-center {
      margin-left: 35px;
    }
    .ak-nav .ak-nav_list > li.current-menu-item > a:before {
      bottom: -4px;
      background-color: #000000da;
    }
  }
  
  @media screen and (max-width: 1199px) {
    .ak-main_header .container {
      max-width: 100%;
    }
    .ak-site_header.ak-style1 {
      .ak-nav {
        display: flex;
      }
    }
  }
  
  .ak-nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li a.active{
      color: #ed1b25;
    }
  }
  
  .ak-sticky_header {
    position: fixed !important;
    width: 100%;
    z-index: 999;
    background: rgba(255, 255, 255, 0.03);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
  }
  
  .ak-gescout_sticky {
    position: fixed !important;
    top: -150px;
    transition: all 0.4s ease;
  }
  
  .ak-gescout_show {
    top: 0 !important;
  }
  
  .ak-site_branding {
    display: inline-block;
    padding: 10px 0;
    img {
      min-height: 80px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .ak-nav_list.ak-color_1 {
      color: #fff;
      ul {
        color: #000;;
      }
    }
    .ak-main_header {
      position: relative;
      .container-fluid {
        padding-right: 40px;
        padding-left: 40px;
      }
    }
    .ak-main_header_center,
    .ak-top_header_center {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .ak-site_header.ak-style1 {
      .ak-main_header_center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        max-width: calc(100% - 300px);
      }
    }
    .ak-nav {
      display: flex;
      align-items: center;
      height: 100%;
      line-height: 1.6em;
      font-size: 16px;
      .ak-nav_list {
        display: flex !important;
        flex-wrap: wrap;
        height: inherit;
        > li {
          margin-right: 50px;
          height: 90px;
          text-transform: uppercase;
          color: #fff;
          &:last-child {
            margin-right: 0;
          }
          > a {
            display: inline-flex;
            position: relative;
            height: inherit;
            align-items: center;
          }
          > ul {
            left: 0;
            top: calc(100% + 30px);
          }
          &:hover {
            > ul {
              top: calc(100% + 0px);
              opacity: 1;
              visibility: visible;
              transition: all 0.4s ease;
            }
          }
          &.menu-item-has-children {
            > a {
              position: relative;
            }
          }
        }
        li:not(.ak-mega_menu) {
          position: relative;
        }
        ul {
          width: 260px;
          background-color: #000000da;
          position: absolute;
          box-shadow: 0px 1px 2px 0px rgba(2, 0, 181, 0.1);
          border-top: 2px solid #ffffff52;
          padding: 10px 0;
          z-index: 100;
          opacity: 0;
          visibility: hidden;
          display: block !important;
          border-radius: 0px 0px 5px 5px;
          transition: all 0.1s ease;
          li {
            &:hover {
              ul {
                top: 0px;
              }
              > ul {
                opacity: 1;
                visibility: visible;
                transition: all 0.4s ease;
              }
            }
          }
          a {
            display: block;
            line-height: inherit;
            padding: 7px 20px;
          }
          ul {
            top: 15px;
            left: 100%;
          }
        }
      }
    }
    .ak-munu_toggle,
    .ak-munu_dropdown_toggle {
      display: none;
    }
    .ak-nav_black_section {
      ul {
        position: relative;
        list-style: none;
        line-height: 65px;
        padding: 0px;
        li {
          margin-top: 40px;
          font-size: 55px;
          text-transform: uppercase;
          font-weight: 900;
        }
      }
      &.ak-font_changes {
        .ak-style_1 {
          ul {
            li {
              font-size: 40px;
              line-height: 14px;
              text-transform: capitalize;
              font-weight: 500;
            }
          }
        }
      }
    }
    .menu-item-has-black-section {
      position: relative;
      span {
        cursor: pointer;
      }
    }
    .menu-item-has-black-section > a {
      position: relative;
    }
    .menu-item-has-black-section > ul {
      padding-left: 40px;
      display: none;
      list-style: none;
      line-height: 30px;
      li {
        margin-top: 40px;
      }
    }
    .ak-munu_dropdown_toggle_1 {
      position: absolute;
      height: 30px;
      width: 35px;
      right: 20px;
      top: 9px;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 2px;
        width: 35px;
        background-color: #fff;
        transition: all 0.3s ease;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(90deg);
      }
      &.active {
        &:before {
          transform: translate(-50%, -50%) rotate(0deg);
        }
      }
    }
    .ak-nav .ak-nav_list {
      .ak-mega_wrapper {
        width: 1296px !important;
        left: 50%;
        transform: translateX(-50%);
        display: flex !important;
        position: absolute;
        padding: 5px 15px 10px;
        a {
          padding: 5px 10px;
          img {
            border-radius: 5px;
            border: 1px solid rgba(181, 181, 181, 0.1);
            transition: all 0.5s ease;
          }
          &:hover {
            img {
              border-color: rgba(#fff, 0.6);
            }
          }
        }
        > li {
          flex: 1;
          padding: 10px 0;
          > a {
            &:hover {
              background-color: transparent;
            }
          }
          ul {
            position: initial;
            border: none;
            padding: 0;
            width: 100%;
            box-shadow: none;
            background-color: transparent;
          }
        }
      }
      .ak-mega_menu {
        &:hover {
          .ak-mega_wrapper {
            li ul {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
    .ak-nav
      .ak-nav_list
      > li
      ul:not(.ak-mega_wrapper)
      .menu-item-has-children
      > a {
      position: relative;
    }
  }
  
  @media screen and (max-width: 1400px) {
    .ak-nav .ak-nav_list .ak-mega_wrapper {
      width: 1116px !important;
    }
    .ak-site-branding {
      max-width: 190px;
    }
    .ak-site_header_full_width {
      .container {
        max-width: 100%;
      }
    }
  }
  
  @media screen and (max-width: 1199px) {
    .ak-nav .ak-nav_list .ak-mega_wrapper {
      width: 100% !important;
    }
    .ak-site_header_full_width {
      .container {
        padding: 0 15px;
      }
    }
    .ak-munu_dropdown_toggle {
      position: absolute;
      height: 30px;
      width: 30px;
      right: 20px;
      top: 5px;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 2px;
        width: 10px;
        background-color: var(--body-color);
        transition: all 0.3s ease;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(90deg);
      }
      &.active {
        &:before {
          transform: translate(-50%, -50%) rotate(0deg);
        }
      }
    }
    .ak-nav {
      .ak-nav_list {
        position: absolute;
        width: 100vw;
        left: -15px;
        background-color: #000000da;
        padding: 10px 0;
        display: none;
        top: 0%;
        padding-top: 75px;
        border-top: 1px solid #4d4d4d52;
        border-bottom: 1px solid #4d4d4d52;
        overflow: auto;
        max-height: calc(100vh - 80px);
        line-height: 1.6em;
        ul {
          padding-left: 15px;
          display: none;
        }
        a {
          display: block;
          padding: 8px 20px;
          text-transform: uppercase;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
      .menu-item-has-children {
        position: relative;
      }
    }
    /*Mobile Menu Button*/
    .ak-munu_toggle {
      display: inline-block;
      width: 30px;
      height: 27px;
      cursor: pointer;
      position: absolute;
      top: 27px;
      right: 30px;
      span,
      span:before,
      span:after {
        width: 100%;
        height: 2px;
        background-color: #fff;
        display: block;
      }
      span {
        margin: 0 auto;
        position: relative;
        top: 12px;
        transition-duration: 0s;
        transition-delay: 0.2s;
        &:before {
          content: "";
          position: absolute;
          margin-top: -9px;
          transition-property: margin, transform;
          transition-duration: 0.2s;
          transition-delay: 0.2s, 0s;
        }
        &:after {
          content: "";
          position: absolute;
          margin-top: 9px;
          transition-property: margin, transform;
          transition-duration: 0.2s;
          transition-delay: 0.2s, 0s;
        }
      }
    }
    .ak-site_header.ak-style1 .ak-munu_toggle {
      top: 50%;
      right: 0px;
      margin-top: -13px;
    }
    .ak-toggle_active {
      span {
        background-color: rgba(0, 0, 0, 0);
        transition-delay: 0.2s;
        &:before {
          margin-top: 0;
          transform: rotate(45deg);
          transition-delay: 0s, 0.2s;
        }
        &:after {
          margin-top: 0;
          transform: rotate(-45deg);
          transition-delay: 0s, 0.2s;
        }
      }
    }
    .ak-header_toolbox {
      margin-right: 50px;
    }
    .ak-nav .ak-nav_list a {
      position: relative;
      text-transform: uppercase;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
    .ak-site_header.ak-style1 .ak-main_header_in {
      height: 80px;
      justify-content: start;
      margin: 0px;
    }
    .ak-site_branding img {
      min-height: 60px;
  }
  .ak-main-header-right{
    margin-left: 20%;
  }
  .gallery__lists img {
    height: 220px;
  }
  .footer.style-1 .footer-content .footer-info .desp{
    margin: 20px 0;
  }
    .ak-hamburger span {
      height: 3px;
    }
    .ak-site_header .current-menu-item > a:before {
      display: none;
    }
    .ak-site_header.ak-style1 .ak-main_header_center {
      .ak-site_branding {
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .ak-site_header.ak-style1 {
      top: 0;
    }
    .header-top {
      display: none;
    }
  }
  
  @media screen and (max-width: 991px) {
    .ak-site_header .container {
      max-width: 100%;
    }
    .ak-site_header.ak-style1 {
      .ak-action_box > *:not(:last-child) {
        margin-right: 25px;
      }
    }
    .ak-site_header.ak-style1 .ak-btn {
      padding: 8px;
    }
    .header-top {
      display: none;
    }
  }
  
  @media screen and (max-width: 575px) {
    .ak-site-branding {
      max-width: 150px;
    }
    .ak-hamburger_wrap .ak-hamburger {
      margin-right: 0;
    }
    .ak-site_branding img {
      max-height: 32px;
      min-height: 54px;
    }
    .ak-site_header.ak-style1 .ak-btn {
      span {
        display: none;
      }
      svg {
        margin-right: 0;
        width: 20px;
        height: 20px;
      }
    }
    .ak-main-header-right {
      display: none;
    }
  }
  
  .nav-bar-border {
    opacity: 0.5;
    height: 1.5px;
    background-color: #ffffff59;
    width: 100%;
  }
  
  .ak-menu-toggle {
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-left: 0.3px solid #635542;
    padding-left: 20px;
    height: 100%;
    .ak-menu-icon path {
      fill: none;
      stroke: #fff;
      stroke-linecap: round;
    }
  }
  
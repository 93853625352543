.swiper-button-prev:after, .swiper-button-next:after {
    font-size: 20px !important;
    color: red;
}
.swiper-button-next, .swiper-button-prev {
    height: 55px !important;
    width: 55px !important;
    /* background-color: #FFF; */
    border-radius: 50% 50% 50% 50%;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease-in-out;
    margin-top: 150px !important;
    margin-right: 60px !important;
}
.swiper-button-prev{
    left: auto !important;
    right: 80px !important;
}
.accordion-item{
    border-radius: 0 !important;
    background-color:transparent !important;
    border: 1px solid #f7102c !important;
  
}
.accordion-button{
    padding: 30px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
    font-size: 22px;
    background-color: transparent !important;
    color: #fff !important;
    font-weight: 600 !important;
    box-shadow: none !important;
}
.accordion-body{
    color: #fff;
}
.accordion-button::after{
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(160deg) brightness(121%) contrast(122%);
}